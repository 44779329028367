<template>
  <div class="content">
    <app-cells position="between">
      <h1 class="title title--theme">Эксперт (ID: {{ form.pk }})</h1>
      <app-button
        theme="error"
        size="small"
        @click="onShowModal"
      >
        Удалить
      </app-button>
    </app-cells>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group required label="Направления">
            <v-select
              v-model="form.directions"
              :reduce="item => item.id"
              :options="directions_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              multiple
              placeholder="Выберите значение"
              class="select select--multiple"
              :class="{ 'select--error': $v.form.directions.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.directions.$dirty && !$v.form.directions.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Фамилия" label-for="last_name">
            <app-input
              v-model="form.last_name"
              id="last_name"
              placeholder="Введите фамилию"
            />
          </app-form-group>
          <app-form-group required label="Имя" label-for="first_name">
            <app-input
              v-model="form.first_name"
              id="first_name"
              placeholder="Введите имя"
              :error="$v.form.first_name.$error"
            />
            <template #error>
              <div v-if="$v.form.first_name.$dirty && !$v.form.first_name.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label="Отчество" label-for="patronymic">
            <app-input
              v-model="form.patronymic"
              id="patronymic"
              placeholder="Введите отчество"
            />
          </app-form-group>
          <app-form-group>
            <app-cropper-web-cam
              v-model="form.photo"
              :aspectRatio="3/4"
              ref="cropper"
            />
            <template #error>
              <div v-if="$v.form.photo.$dirty && !$v.form.photo.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group required label="Пол">
            <app-cells position="start" :indent="false">
              <app-radio
                v-for="gender in gender_options"
                :key="gender.id"
                v-model.number="form.gender"
                :value="gender.id"
                :label="gender.name"
                name="gender"
              />
            </app-cells>
            <template #error>
              <div v-if="$v.form.gender.$dirty && !$v.form.gender.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Размер одежды">
            <v-select
              v-model="form.clothingsize"
              :reduce="item => item.id"
              :options="clothingsize_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.clothingsize.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.clothingsize.$dirty && !$v.form.clothingsize.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group required label="Тип питания">
            <v-select
              v-model="form.foot_type"
              :reduce="item => item.id"
              :options="meal_options"
              :filterable="false"
              :clearable="false"
              :searchable="false"
              label="name"
              placeholder="Выберите значение"
              class="select"
              :class="{ 'select--error': $v.form.foot_type.$error }"
            >
              <template #open-indicator>
                <svg width="18" height="18" fill="none" class="open-indicator"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path
                    d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                    fill="#fff"></path>
                </svg>
              </template>
              <template #option="option">
                <div class="select__item d-center">{{ option.name }}</div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">{{ option.name }}</div>
              </template>
              <template #no-options>Ничего не найдено</template>
            </v-select>
            <template #error>
              <div v-if="$v.form.foot_type.$dirty && !$v.form.foot_type.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit" :disabled="$v.form.$error">Сохранить</app-button>
      </app-cells>
    </form>
    <modal
      name="delete-modal"
      :width="750"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="modal">
        <div class="modal__title modal__title--center-black">Подтверждение действия</div>
        <p class="modal__text">
          Вы собираетесь удалить эксперта (ID: {{ form.pk }}).
          Это действие невозможно будет отменить и данные будут удалены безвозвратно. Вы уверены, что хотите это сделать?
        </p>
        <app-cells position="center">
          <app-button size="small" @click="onDeleteService" ref="submitDelete">Да</app-button>
          <app-button size="small" @click="onHideModal">Нет</app-button>
        </app-cells>
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  getClothingSize,
  getDirections,
  getGender,
  getMealType,
  putExpert,
  getExpert,
  deleteExpert
} from '@/http'

export default {
  name: 'ExpertsCreate',
  data() {
    return {
      form: {},
      meal_options: [],
      clothingsize_options: [],
      gender_options: [],
      directions_options: [],
    }
  },
  validations: {
    form: {
      first_name: { required },
      photo: { required },
      gender: { required },
      foot_type: { required },
      clothingsize: { required },
      directions: { required },
    }
  },
  created() {
    getExpert(this.$route.params.id)
      .then(response => {
        this.form = response.data
      })
    getGender()
      .then(response => {
        this.gender_options = response.data
      })
    getMealType()
      .then(response => {
        this.meal_options = response.data
      })
    getClothingSize()
      .then(response => {
        this.clothingsize_options = response.data
      })
    getDirections()
      .then(response => {
        this.directions_options = response.data
      })
  },
  methods: {
    onShowModal() {
      this.$modal.show('delete-modal')
    },
    onHideModal() {
      this.$modal.hide('delete-modal')
    },
    onDeleteService() {
      this.$refs.submitDelete.preload = true
      deleteExpert(this.$route.params.id)
        .finally(() => {
          this.$refs.submitDelete.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Эксперт удален'
          })
          this.$router.push({ name: 'experts-list' })
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        if (this.form.photo.coords && !this.form.photo.photo) {
          this.$refs.cropper.sendCrop().then(() => {
            this.form.photo_id = this.form.photo.photo
            this.form.coords = this.form.photo.coords
            this.sendForm()
          })
        } else {
          this.form.coords = this.form.photo.coords
          this.sendForm()
        }
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      delete this.form.photo
      putExpert(this.$route.params.id, this.form)
        .finally(() => {
          this.$refs.submit.preload = false
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Эксперт изменен'
          })
          this.$router.push({ name: 'experts-list' })
        })
    },
  }
}
</script>

